(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

jQuery(document).ready(function ($) {
  /**
   * Painel Principal ( Home )
   */
  var swiper = new Swiper('.s1', {
    pagination: '.swiper-pagination',
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    paginationClickable: true,
    // spaceBetween:0,
    //centeredSlides: true,
    loop: true,
    autoplay: 4000,
    effect: 'fade',
    preventClicks: true,
    autoplayDisableOnInteraction: false
  });
  /**
   * Logos ( Carousel )
   */

  var swiper = new Swiper('.s2', {
    slidesPerView: 5,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    paginationClickable: true,
    loop: true,
    autoplay: 2000,
    autoplayDisableOnInteraction: false,
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 40
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10
      }
    }
  });
  /**
   * Fancybox
   */

  $(".gal-item a").addClass('fancybox');
  $(".fancybox").attr('rel', 'gallery');
  $(".fancybox").fancybox({
    maxWidth: 1200,
    maxHeight: 768,
    fitToView: false,
    width: '100%',
    height: '100%',
    autoSize: false,
    closeClick: false,
    openEffect: 'none',
    closeEffect: 'none'
  });
  /**
   * Se for mobile insere not-hover nos elementos setados
   */

  /*
   if(is_Mobile()) {
       jQuery(document).ready(function($) {
          $('a').addClass('not-hover');
       });
   }
   */

  /**
   * Menu do bootstrap com hover ao inves de click
   */

  $('ul.nav li.dropdown').hover(function () {
    $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
  }, function () {
    $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
  });
  /**
   * Fancybox e form com callback exemplo
   */

  $("a.form-fancy").on('click', function () {
    $.fancybox($('.formulario').html(), {
      'maxWidth': 990,
      'maxHeight': 768,
      'autoScale': false,
      'transitionIn': 'none',
      'transitionOut': 'none',
      'hideOnContentClick': false,
      afterShow: function afterShow() {
        $('.telefone input').focusout(function () {
          var phone, element;
          element = $(this);
          element.unmask();
          phone = element.val().replace(/\D/g, "");

          if (phone.length > 10) {
            element.mask('(99) 99999-9999');
          } else {
            element.mask('(99) 9999-99999');
          }
        }).trigger('focusout');
        $(".ida input").datepicker({
          changeMonth: true,
          changeYear: true,
          onClose: function onClose(selectedDate) {
            $(".volta input").datepicker("option", "minDate", selectedDate);
          }
        });
        $(".volta input").datepicker({
          changeMonth: true,
          changeYear: true,
          onClose: function onClose(selectedDate) {
            $(".ida input").datepicker("option", "maxDate", selectedDate);
          }
        });
      }
    });
  });
  /**
   * Adiciona classe no menu
   */

  $('.menu-item > a').addClass('menu-link');
  /**
   * Alert nos forms
   */

  $('.wpcf7-form-control-wrap span').addClass('bg-danger');
  /**
   * Lazy load para carregamento de imagens
   */

  $(".lazy").lazyload({
    effect: "fadeIn"
  });
  /**
   * Colocando active no menu on page
   */

  /*$('.navbar li').click(function(e) {
   $('ul.nav > li').click(function (e) {
   e.preventDefault();
   $('ul.nav > li').removeClass('active');
   $(this).addClass('active');
   });
   })*/

  /**
   * Filtro do select2 pra pagina representantes
   */

  /*$(".js-example-placeholder-single").select2({
   placeholder: "Selecione a cidade",
   allowClear: true,
   language: "pt_PT"
   });
     $(".js-states").select2({
   placeholder: "Selecione o estado",
   allowClear: true,
   language: "PT"
   });*/

  /**
   * js pra mudar de cor e add class adicional no header
   */

  $(window).scroll(function () {
    if ($(document).scrollTop() > 120) {
      $('nav').addClass('shrink');
    } else {
      $('nav').removeClass('shrink');
    }
  });
  /**
   * active dos marcadores no menu
   */
  // add a class

  $('.menu-item').addClass('menu__item');
  $('.menu__item a').addClass('menu__link');
  $('.menu__item a').addClass('menu__link'); // add a class active no 1 item "a"

  $('.menu-item-4 a').addClass('menu__link--active'); // remove class padrao do bt

  $('.menu-item').removeClass('active');
  /*começa a magia dos actives*/

  $('.menu__link').on('click', function () {
    //c.preventDefault();
    var id = $(this).attr('href'),
        targetOffset = $(id).offset().top; // add

    $('.menu__item a').removeClass('menu__link--active');
    $(this).addClass('menu__link--active'); // anima

    $('html,body').animate({
      scrollTop: targetOffset - 100
    }, 500);
  }); // $('.navbar-toggler').click(function () {
  //     $( ".swiper-container" ).animate({                           
  //         margin:"+120px auto",
  //         margin:"toggle"            
  //     }, 1000, function() {
  //       // Animation complete.
  //     });
  //   });

  $('.to-top').click(function () {
    $("html, body").animate({
      scrollTop: 0
    }, 600);
    return false;
  });
  $('.back-li').click(function () {
    window.history.back();
  });
  $('.large-font').click(function (event) {
    event.preventDefault();
    $(".post-tip").animate({
      "font-size": "17px"
    });
    $("h4").animate({
      "font-size": "20px"
    });
    $("h5").animate({
      "font-size": "20px"
    });
    $("p").animate({
      "font-size": "17px"
    });
  });
  $('.small-font').click(function (event) {
    event.preventDefault();
    $(".post-tip").animate({
      "font-size": "14px"
    });
    $("h4").animate({
      "font-size": "14px"
    });
    $("h5").animate({
      "font-size": "14px"
    });
    $("p").animate({
      "font-size": "12px"
    });
  });
  $('.normal-font').click(function (event) {
    event.preventDefault();
    $(".post-tip").animate({
      "font-size": "16px"
    });
    $("h4").animate({
      "font-size": "16px"
    });
    $("h5").animate({
      "font-size": "15px"
    });
    $("p").animate({
      "font-size": "14px"
    });
  });
});

},{}]},{},[1]);
